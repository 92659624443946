import { useState, useEffect } from 'react';
const useIntersection = (element, rootMargin) => {
    const [isVisible, setState] = useState(false);
    useEffect(() => {
        const observer = new IntersectionObserver(_ref => {
            let [entry] = _ref;
            setState(entry.isIntersecting);
        }, {
            rootMargin
        });
        element.current && observer.observe(element.current);
        return () => element.current && observer.unobserve(element.current);
    }, []);
    return isVisible;
};
export default useIntersection;
